import React from "react";
import Sajunga from "../assets/sajunga.gif";
import KristausKelias from "../assets/kristausKelias.png";
import ZmonuMalduGalia from "../assets/zmonuMalduGalia.png";
import LTYouth from "../assets/ltyouth.jpg";
import KiekvieniNamaiKristui from "../assets/kiekvieniNamaiKristui.jpg";
import MaldosNamaiVisomsTautoms from "../assets/maldosNamaiVisomsTautoms.jpg";
import Priegblobstis from "../assets/prieglobstis.jpg";
import Asambleja from "../assets/asambleja.png";

const Partneriai = () => {
  return (
    <div className="flex flex-col items-center justify-center mt-16 pt-8 ">
      <div className="text-5xl md:text-7xl pb-8 md:pb-16  font-semibold ">
        Partneriai
      </div>

      <div className="grid  items-center sm:grid-cols-2 md:grid-cols-3 gap-16 px-16 w-3/4">
        <div className="col-span">
          <a
            href="https://www.pentecost.lt/apie-mus/"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="h-auto max-w-full rounded-lg"
              src={Sajunga}
              alt=""
            />
          </a>
        </div>
        <div className="col-span-2">
          <a
            href="https://www.kristauskelias.lt/lt/"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="h-auto max-w-full rounded-lg "
              src={KristausKelias}
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="https://kristui.lt/"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="h-auto max-w-full rounded-lg"
              src={KiekvieniNamaiKristui}
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/JaunimoTarnavimasLTYOUTH/"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="h-auto max-w-full rounded-lg"
              src={LTYouth}
              alt=""
            />
          </a>
        </div>

        <div>
          <a
            href="https://www.facebook.com/mnvtchurch/"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="h-auto max-w-full rounded-lg"
              src={MaldosNamaiVisomsTautoms}
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/groups/229417710806629/?epa=SEARCH_BOX"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="h-auto max-w-full rounded-lg"
              src={ZmonuMalduGalia}
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.prieglobstis.lt/"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="h-auto max-w-full rounded-lg"
              src={Priegblobstis}
              alt=""
            />
          </a>
        </div>
        <div>
          <a
            href="https://worldagfellowship.org/"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="h-auto max-w-full rounded-lg"
              src={Asambleja}
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Partneriai;
