import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../assets/arrow-right.svg";
import LogoSajunga from "../assets/logo_sajunga.png";
import LogoFacebook from "../assets/logo_facebook.png";
import LogoYoutube from "../assets/logo_youtube.png";
import BannerBible from "../assets/banner_3_bible.jpg";
import BannerRoad from "../assets/banner_2_road.jpg";
import BannerPraying from "../assets/banner_1_praying.jpg";
import Kryzius from "../assets/kryzius.png";

export default function Home() {
  return (
    <div className="flex flex-col items-center  justify-center  bg-cover bg-center  -translate-y-2">
      <div
        id="background"
        className=" w-full flex flex-col items-center justify-center h-screen gap-4">
        <div className="flex flex-col md:flex-row items-center justify-center ">
          <img
            src={Kryzius}
            className="px-8 py-8  w-80 md:w-96 h-auto"
            alt="kryžius"
          />
          <h1 className="text-white font-bold text-4xl md:text-7xl pb-8 text-center leading-relaxed md:w-1/3">
            Išgelbėjimo Šviesa
          </h1>
        </div>
        <Link to="/apie-mus">
          <div className="border-white border-4 rounded-3xl flex flex-row text-3xl md:text-4xl items-center text-white px-8 py-2 md:py-8 gap-2 md:gap-8 mx-4">
            <button
              type="button"
              className="leading-10	 font-medium   text-center me-2 ">
              Tu esi laukiamas
            </button>
            <ArrowRight className="h-10 w-12" />
          </div>
        </Link>
      </div>

      <div className=" min-h-min inline-flex justify-center items-center py-16 md:py-8 bg-white w-full">
        <h2 className="text-4xl font-bold text-center text-[#513b34]">
          Mes – Krikščionių sekmininkų bažnyčia Klaipėdoje{" "}
        </h2>
      </div>
      {/*  Musu vizija */}
      <div className="w-full">
        <div className=" bg-[#513b34]   md:h-96 mb-16 min-h-min   py-16 text-center  flex flex-col items-center justify-center gap-8 md:gap-14 rounded-lg shadow m-2 ">
          <h3 className="text-white text-5xl font-bold text-center w-3/4 ">
            Mūsų vizija – Skelbti | Atstatyti | Paruošti
          </h3>
          <div className="grid  place-content-center place-items-center min-h-min md:grid-cols-3  gap-8  text-3xl  w-full md:w-3/4  ">
            <div className="flex flex-col text-white font-bold text-center min-h-min">
              <span>Skelbti:</span>
              <span> išgelbėjimą, Kristų, Evangeliją.</span>
            </div>
            <div className="flex flex-col text-white font-bold text-center">
              <span>Atstatyti: </span>
              <span>sužeistas širdis ir gyvenimus.</span>
            </div>
            <div className="flex flex-col text-white font-bold text-center">
              <span>Paruošti:</span>
              <span>Dievo karalystės tarnautojus.</span>
            </div>
          </div>
        </div>
      </div>
      {/* image banner */}
      <div className="w-full flex flex-col md:flex-row justify-evenly items-center py-8 px-4 md:px-8  gap-8">
        <Link to="/malda">
          <div className="relative">
            <img
              src={BannerPraying}
              className="w-auto h-64  -brightness-200 rounded-2xl"
              alt="malda"
            />
            <div className="text-3xl text-center leading-tight text-white w-full font-bold absolute top-1/2 bg-black bg-opacity-50 py-2 inline-flex justify-center items-center">
              <span>Man reikia maldos</span>
            </div>
          </div>
        </Link>
        <Link to="/klausimai">
          <div className="relative">
            <img
              src={BannerRoad}
              className="w-auto h-64  -brightness-200 rounded-2xl"
              alt="Išgelbėjimo kelias"
            />
            <div className="text-3xl text-center leading-tight text-white w-full font-bold absolute top-1/2 bg-black bg-opacity-50 py-2 inline-flex justify-center items-center">
              <span>Išgelbėjimo kelias</span>
            </div>
          </div>
        </Link>
        <a href="https://www.bible.com/bible/321/MRK.1.LTB">
          <div className="relative">
            <img
              src={BannerBible}
              className="w-auto h-64  -brightness-200 rounded-2xl"
              alt="biblijos skaitymas"
            />
            <div className="text-3xl text-center leading-tight text-white w-full font-bold absolute top-1/2 bg-black bg-opacity-50 py-2 inline-flex justify-center items-center">
              <span>Pradėk skaityti Dievo žodį</span>
            </div>
          </div>
        </a>
      </div>
      {/* Logos Banner */}
      <div className="w-full flex flex-row justify-evenly items-center py-8">
        <div className="w-48 h-48">
          <a
            href="https://www.pentecost.lt/"
            target="_blank"
            rel="noopener noreferrer">
            <img src={LogoSajunga} alt="Sąjungos logotipas" />
          </a>
        </div>
        <div className="w-48 h-48">
          <a
            href="https://www.facebook.com/IsgelbejimoSviesa"
            target="_blank"
            rel="noopener noreferrer">
            <img src={LogoFacebook} alt="Facebook logotipas" />
          </a>
        </div>
        <div className="w-44 h-44">
          <a
            href="https://www.youtube.com/@IsgelbejimoSviesa"
            target="_blank"
            rel="noopener noreferrer">
            <img src={LogoYoutube} alt="Youtube logotipas" />
          </a>
        </div>
      </div>
      {/* Kur mes - geolocation */}
      <div className=" w-full md:w-9/10  items-center justify-evenly flex lg:flex-row flex-col  gap-16 md:gap-4 md:px-16 my-16">
        <div className="flex flex-col    justify-center gap-4 px-4 ">
          <div className="font-bold text-2xl underline text-yellow-950 ">
            Klaipėda, Vingio g. 21, 2 aukštas.
          </div>
          <div className="font-bold text-xl text-yellow-950">
            Pastorius – Dmitrij Romančik
          </div>
          <div className="font-bold text-xl text-yellow-950">
            Tel. nr. +370 682 30509
          </div>
          <div className="font-bold text-xl text-yellow-950">
            info@isgelbejimosviesa.lt
          </div>
          <div className="font-bold text-xl text-yellow-950">
            Sekmadienio pamaldos – 11:00
          </div>
        </div>
        <div className="relative  h-96  w-full ">
          <iframe
            className="absolute top-0 left-0 w-full h-full px-16 sm:px-4"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1795.4442849128375!2d21.193900346873715!3d55.66888905878548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e4dc7349b0aeb9%3A0xb52f08f9b4ff4f94!2zQmHFvm55xI1pYSDigJ5JxaFnZWxixJdqaW1vIMWgdmllc2HigJw!5e1!3m2!1slt!2slt!4v1703079441543!5m2!1slt!2slt"
            style={{ border: 0 }}
            allowFullScreen={false}
            aria-hidden={false}
            title="Church Location | Bažnyčios lokacija"></iframe>
        </div>
      </div>
    </div>
  );
}
