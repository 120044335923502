import React from "react";

const Footer = () => {
  return (
    <footer className="flex flex-col items-center sm:flex-row justify-center bg-[#513b34]  shadow    py-4 px-2  gap-4 z-50 w-full ">
      <span className="text-white text-xs font-medium">
        Klapėdos m. Vingio g. 21, 2 aukštas
      </span>
      <a className="text-white text-xs " href="tel:+37068230509 font-medium">
        +370&nbsp;682&nbsp;30509
      </a>
      <a
        href="mailto: info@isgelbejimosviesa.lt"
        className="text-white text-xs font-medium">
        info@isgelbejimosviesa.lt
      </a>
      <span className="text-white text-xs font-medium">
        Bažnyčia Išgelbėjimo Šviesa ©{" "}
      </span>
    </footer>
  );
};

export default Footer;
