import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logotipas from "../assets/logotipas.png";

export default function Navbar() {
  // Styling:
  const NavLinkStyle = ({ isActive }) => {
    if (isActive) {
      return " justify-center   text-blue-600 text-lg ";
    }
    return " block py-2  text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0   text-lg ";
  };
  const DropdownLinkStyle = ({ isActive }) => {
    let conditionalStyles = isActive
      ? ` block text-blue-600  `
      : ` text-gray-900  `;

    // Add additional styles that apply for all cases here:
    conditionalStyles += `justify-center block  rounded hover:bg-gray-200 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-xl font-medium py-2`;

    return conditionalStyles;
  };

  // States and Logic:
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownMenu, setDropdownMenu] = useState(false);

  const dropdownRef = useRef(null);

  const handleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    setDropdownMenu(false);
  };

  const handleDropdownMenu = () => {
    setDropdownMenu(!dropdownMenu);
  };
  const closeDropdownMenu = () => {
    setMobileMenuOpen(false);
    setDropdownMenu(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains("md:w-auto") // Adjust this selector as needed
      ) {
        setDropdownMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <nav className="bg-gray-100 border-gray-200  fixed w-full z-10">
      <div className="max-w-screen-xl flex flex-col lg:flex-row items-center md:items-start lg:items-center justify-between mx-auto p-4 relative">
        <div className="flex  items-center  max-w-screen-xl justify-between w-full md:w-4">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={Logotipas} className="h-8" alt="Išgelbėjimo Šviesa" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap ">
              Išgelbėjimo Šviesa
            </span>
          </Link>
          <button
            type="button"
            onClick={handleMobileMenu}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 ">
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14">
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>

        <div
          className={
            mobileMenuOpen
              ? " max-w-screen-xl md:block md:w-auto w-full"
              : "hidden max-w-screen-xl md:block md:w-auto w-full"
          }
          id="navbar-dropdown">
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg  md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0  w-full justify-between">
            <li>
              <NavLink
                exact
                to="/"
                className={NavLinkStyle}
                onClick={closeDropdownMenu}>
                Pradžia
              </NavLink>
            </li>
            <li>
              <button
                className="flex items-center  text-lg justify-between w-full py-2  text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto "
                onClick={handleDropdownMenu}>
                Apie mus
                <svg
                  className={`w-5 h-5 ms-5 transform transition-transform duration-300  ${
                    dropdownMenu ? "rotate-180" : "rotate-0"
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              {dropdownMenu && (
                <div
                  ref={dropdownRef}
                  className={`md:absolute   md:mt-2 md:bg-white  md:border md:border-gray-200    rounded-lg md:shadow-lg z-20 md:w-32 w-full `}>
                  <ul
                    className="pl-2 divide-y md:divide-gray-200 "
                    aria-labelledby="dropdownLargeButton">
                    <li className="md:flex md:justify-center ">
                      <NavLink
                        exact
                        to="/apie-mus"
                        className={DropdownLinkStyle}
                        onClick={closeDropdownMenu}>
                        Apie mus
                      </NavLink>
                    </li>

                    <li className="md:flex md:justify-center ">
                      <NavLink
                        exact
                        to="/tikejimas"
                        className={DropdownLinkStyle}
                        onClick={closeDropdownMenu}>
                        Tikėjimas
                      </NavLink>
                    </li>
                    <li className="md:flex md:justify-center ">
                      <NavLink
                        exact
                        to="/partneriai"
                        className={DropdownLinkStyle}
                        onClick={closeDropdownMenu}>
                        Partneriai
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </li>

            <li>
              <NavLink
                exact
                to="/tarnystes"
                className={NavLinkStyle}
                onClick={closeDropdownMenu}>
                Tarnystės
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/klausimai"
                className={NavLinkStyle}
                onClick={closeDropdownMenu}>
                Turi klausimų
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/malda"
                className={NavLinkStyle}
                onClick={closeDropdownMenu}>
                Malda
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/aukoti"
                className={NavLinkStyle}
                onClick={closeDropdownMenu}>
                Aukoti
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
