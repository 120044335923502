import React, { useState } from "react";
import { ReactComponent as ArrowTopRightCorner } from "../assets/arrow-top-right.svg";

const Tikejimas = () => {
  const [accordionItems, setAccordionItems] = useState([
    {
      id: 1,
      active: false,
      body: "Į Vienintelį Tikrąjį Dievą, kuris apsireiškė žmonėms, kaip esantis Trijuose Asmenyse: Dievas Tėvas, Dievas Sūnus, Dievas Šventoji Dvasia.",
      verses: [
        { name: "Pak Įst 6:4", url: "https://www.bible.com/bible/321/deu.6.4" },
        { name: "Mt 28:19", url: "https://www.bible.com/bible/321/mat.28.19" },
        { name: "1 Jono 5:7", url: "https://www.bible.com/bible/321/1jn.5.7" },
      ],
    },
    {
      id: 2,
      active: false,
      body: "Kad 66 kanono knygos, Biblija, yra Šventi Dievo įkvėpti, vieninteliai neklaidingi Raštai.",
      verses: [
        {
          name: "2 Pet. 1:21",
          url: "https://www.bible.com/bible/321/2pe.1.21",
        },
        {
          name: " 2 Tim. 3:16-17",
          url: "https://www.bible.com/bible/321/2ti.3.16-17",
        },
      ],
    },
    {
      id: 3,
      active: false,
      body: " Į Jėzaus Kristaus nekaltą prasidėjimą, nenuodėmingą gyvenimą, stebuklus, kuriuos Jis padarė, Jo atperkamąją mirtį, prisikėlimą kūne, įžengimą į Dangų, užtarimą Tėvo akivaizdoje, Jo antrąjį atėjimą, kaip palaimintą viltį visų tų, kurie įtikėjo Jėzų Kristų. ",
      verses: [
        { name: "Iz. 7:14", url: "https://www.bible.com/bible/321/isa.7.14" },
        { name: "Mt. 1:23", url: "https://www.bible.com/bible/321/mat.1.23" },
        { name: "Jn. 20:30", url: "https://www.bible.com/bible/321/jhn.20.30" },
        {
          name: "Heb. 7:25-26",
          url: "https://www.bible.com/bible/321/heb.7.25-26",
        },
        {
          name: "1 Kor. 15:3-4",
          url: "https://www.bible.com/bible/321/1co.15.3-4",
        },
        {
          name: "Ap.d. 1:9-11",
          url: "https://www.bible.com/bible/321/act.1.9-11",
        },
        { name: "Heb. 9:24", url: "https://www.bible.com/bible/321/heb.9.24" },
        {
          name: "1 Tes. 4:13-18",
          url: "https://www.bible.com/bible/321/1th.4.13-18",
        },
      ],
    },
    {
      id: 4,
      active: false,
      body: "Kad žmogus yra sukurtas Dievo; vėliau nusidėjo per nepaklusnumą Jam. ",
      verses: [
        {
          name: "Pr. 1:26-27",
          url: "https://www.bible.com/bible/321/gen.1.26-27",
        },
        { name: " Pr. 3:17", url: "https://www.bible.com/bible/321/gen.3.17" },
        { name: "Rom. 5:12", url: "https://www.bible.com/bible/321/rom.5.12" },
      ],
    },
    {
      id: 5,
      active: false,
      body: "Kad išgelbėjimas žmogui suteikiamas dovanai, tikėjimu į Jėzų Kristų, kuris atpirko mus iš nuodėmių. ",
      verses: [
        {
          name: "Ef. 2:8",
          url: "https://www.bible.com/bible/321/eph.2.8",
        },
        { name: "Tit. 3:5", url: "https://www.bible.com/bible/321/tit.3.5" },
        {
          name: "1 Kor. 15:3-4",
          url: "https://www.bible.com/bible/321/1co.15.3-4",
        },
        {
          name: "Gal. 2:16",
          url: "https://www.bible.com/bible/321/gal.2.16",
        },
      ],
    },
    {
      id: 6,
      active: false,
      body: "Kad žmogaus atgimimas iš aukšto vyksta per Dievo Žodžio priėmimą ir tikėjimą į Jėzų Kristų, veikiant Šventajai Dvasiai.",
      verses: [
        {
          name: "Jok 1:18",
          url: "https://www.bible.com/bible/321/jas.1.18",
        },
        {
          name: "1 Jn 5:10-13",
          url: "https://www.bible.com/bible/321/1jn.5.10-13",
        },
        {
          name: "1 Jn 3:5-6",
          url: "https://www.bible.com/bible/321/1jn.3.5-6",
        },
      ],
    },
    {
      id: 7,
      active: false,
      body: "Į bažnyčią sudarytą iš visų išgelbėtų žmonių tikėjimu į Jėzų Kristų. Bažnyčia yra Kristaus kūnas, o Kristus yra bažnyčios galva. ",
      verses: [
        {
          name: "Mt. 16:18",
          url: "https://www.bible.com/bible/321/mat.16.18",
        },
        {
          name: "Apr. 5:9-10",
          url: "https://www.bible.com/bible/321/rev.5.9-10",
        },
        {
          name: "Ef. 1:22-23",
          url: "https://www.bible.com/bible/321/eph.1.22-23",
        },
        {
          name: "Ef. 2:20-22",
          url: "https://www.bible.com/bible/321/eph.2.20-22",
        },
      ],
    },
    {
      id: 8,
      active: false,
      body: "Kad krikštas atliekamas pilnu panardinimu į vandenį vardan Tėvo ir Sūnaus ir Šventosios Dvasios.",
      verses: [
        {
          name: "Mt. 3:16-17",
          url: "https://www.bible.com/bible/321/mat.3.16-17",
        },
        {
          name: "Mt. 28:19",
          url: "https://www.bible.com/bible/321/mat.28.19",
        },
      ],
    },
    {
      id: 9,
      active: false,
      body: "Krikštą į Šventąją Dvasią su kitų kalbų ženklu.",
      verses: [
        {
          name: "Apd. 2:2-4",
          url: "https://www.bible.com/bible/321/act.2.2-4",
        },
        {
          name: "Apd. 10:44-46",
          url: "https://www.bible.com/bible/321/act.10.44-46",
        },
        {
          name: "Apd. 11:14-16",
          url: "https://www.bible.com/bible/321/act.11.14-16",
        },
        {
          name: "Apd. 19:6",
          url: "https://www.bible.com/bible/321/act.19.6",
        },
      ],
    },
    {
      id: 10,
      active: false,
      body: "Į Šventosios Dvasios dovanų veikimą, jo Bažnyčioje, kaip neatšaukiamą Dievo nuostatą.",
      verses: [
        {
          name: "1 Kor. 12:4-11",
          url: "https://www.bible.com/bible/321/1co.12.4-11",
        },
        {
          name: "Ef. 4:7-16",
          url: "https://www.bible.com/bible/321/eph.4.7-16",
        },
      ],
    },
    {
      id: 11,
      active: false,
      body: "Kiekvienas kuris įtikėjo turi būti Kristaus mokiniu, atnaujindamas protą ir panašėdamas į Jo charakterį.",
      verses: [
        {
          name: "1 Pet. 1:14-16",
          url: "https://www.bible.com/bible/321/1pe.1.14-16",
        },
        {
          name: "Heb. 12:14",
          url: "https://www.bible.com/bible/321/heb.12.14",
        },
        {
          name: "1 Tes. 4:1-7",
          url: "https://www.bible.com/bible/321/1th.4.1-7",
        },
        {
          name: " Mt 28:19-20",
          url: "https://www.bible.com/bible/321/mat.28.19-20",
        },
      ],
    },
    {
      id: 12,
      active: false,
      body: "Į dieviškąjį ligonių išgydymą, pagal jų tikėjimą ir Dievo valią. ",
      verses: [
        {
          name: "Mt. 8:13",
          url: "https://www.bible.com/bible/321/mat.8.13",
        },
        {
          name: "Jok. 5:13-16",
          url: "https://www.bible.com/bible/321/jas.5.13-16",
        },
        {
          name: "Iz. 53:4-5",
          url: "https://www.bible.com/bible/321/isa.53.4-5",
        },
        {
          name: "Mt. 8:16-17",
          url: "https://www.bible.com/bible/321/mat.8.16-17",
        },
      ],
    },
    {
      id: 13,
      active: false,
      body: "Viešpaties vakarienė yra vykdoma Jėzaus Kristaus atminimui. ",
      verses: [
        {
          name: "Mt. 26:26-29",
          url: "https://www.bible.com/bible/321/mat.26.26-29",
        },
        {
          name: "1 Kor. 11:23-32",
          url: "https://www.bible.com/bible/321/1co.11.23-32",
        },
      ],
    },
    {
      id: 14,
      active: false,
      body: "Į Kristaus Antrąjį Atėjimą, Bažnyčios paėmimą, mirusiųjų prisikėlimą, Paskutinį teisiųjų ir neteisiųjų Dievo Teismą. ",
      verses: [
        {
          name: "Apr. 20:1-6",
          url: "https://www.bible.com/bible/321/rev.20.1-6",
        },
        {
          name: "Apr 20:10-15",
          url: "https://www.bible.com/bible/321/rev.20.10-15",
        },
      ],
    },
  ]);
  const handleAccordion = (id) => {
    setAccordionItems(
      accordionItems.map((item) => ({
        ...item,
        active: item.id === id ? !item.active : item.active,
      }))
    );
  };
  return (
    <div className="mt-16 pt-8 w-full flex flex-col items-center">
      <h1 className="text-[#513b34] font-bold text-5xl text-center">
        Tikėjimo pagrindai
      </h1>
      <h2 className="font-bold text-4xl pt-4">Mes tikime: </h2>
      <div className="w-full px-4  md:w-3/5 md:px-4 mt-8">
        {accordionItems.map((item, index) => (
          <div key={item.id}>
            <button
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-black border-b border-gray-200  gap-3"
              onClick={() => handleAccordion(item.id)}
              aria-expanded={item.active}
              aria-controls={item.id}>
              <span className="text-justify text-xl ">{item.body}</span>
              <div>
                <svg
                  className={`w-6 h-6 ${item.active ? "" : "rotate-180"}`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </div>
            </button>
            <div
              id={item.id}
              className={`${item.active ? "" : "hidden"}`}
              aria-labelledby={item.id}>
              <blockquote className="p-4 my-4 border-s-4 border-gray-300 bg-gray-50 ">
                <div className="flex flex-wrap gap-4">
                  {item.verses.map((child) => (
                    <a
                      href={child.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text flex flex-row gap-1 text-blue-900 font-extrabold no-underline">
                      <span className="text-xl text-gray-500 italic font-medium leading-relaxed  underline">
                        {child.name}
                      </span>
                      <ArrowTopRightCorner />
                    </a>
                  ))}
                </div>
              </blockquote>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tikejimas;
