import React, { useState } from "react";
import { ReactComponent as ArrowTopRightCorner } from "../assets/arrow-top-right.svg";

const Questions = () => {
  const [accordionItems, setAccordionItems] = useState([
    {
      id: "accordion-flush-body-1",
      active: false,
      title: "Mes visi esam nusidėję iš prigimties ir savo pasirinkimais:",
      body: '"nes visi nusidėjo ir stokoja Dievo šlovės"',
      verse: "Romiečiams 3:23",
      verseURL: "https://www.bible.com/lt/bible/321/ROM.3.23.KBV",
    },
    {
      id: "accordion-flush-body-2",
      active: false,
      title: "Mes priimame amžinąjį gyvenimą, kaip dovaną:",
      body: "„Atpildas už nuodėmę – ­mirtis, o Dievo dovana – ­amžinasis gyvenimas per Jėzų Kristų, mūsų Viešpatį“",
      verse: "Romiečiams 6:23",
      verseURL: "https://www.bible.com/lt/bible/321/ROM.6.23.KBV",
    },
    {
      id: "accordion-flush-body-3",
      active: false,
      title: "Dievas parodė Savo meilę mums, Jo priešams:",
      body: "„O Dievas mums parodė savo meilę tuo, kad Kristus mirė už mus, kai tebebuvome nusidėjėliai“",
      verse: "Romiečiams 5:8",
      verseURL: "https://www.bible.com/lt/bible/321/ROM.5.8.KBV",
    },
    {
      id: "accordion-flush-body-4",
      active: false,
      title:
        "Mes turime pasitikėti ir pasiduoti Jėzui Kristui, kaip Viešpačiui:",

      body: "„Jeigu lūpomis išpažinsi Viešpatį Jėzų ir širdimi tikėsi, kad Dievas Jį prikėlė iš numirusių, būsi išgelbėtas.\nNes širdimi tikima, ir taip įgyjamas teisumas, o lūpomis išpažįstama, ir taip įgyjamas išgelbėjimas.“",
      verse: "Romiečiams 10:9-10",
      verseURL: "https://www.bible.com/lt/bible/321/ROM.10.9-10.KBV",
    },
    {
      id: "accordion-flush-body-5",
      active: false,
      title: "Mūsų užtikrintumas išgelbėjime - Jėzus Kristus",

      body: "„juk “kiekvienas, kuris šaukiasi Viešpaties vardo, bus išgelbėtas”. “",
      verse: "Romiečiams 10:13",
      verseURL: "https://www.bible.com/lt/bible/321/ROM.10.13.KBV",
    },
  ]);

  const handleAccordion = (id) => {
    setAccordionItems(
      accordionItems.map((item) => ({
        ...item,
        active: item.id === id ? !item.active : item.active,
      }))
    );
  };
  return (
    <div className="flex flex-col items-center mt-16 pt-8">
      <div className="text-6xl text-center">
        Kaip išsigelbėti ir gyventi amžinai?
      </div>

      <div className="w-full px-4  md:w-3/5 md:px-4 mt-8">
        <div id="accordion-flush">
          {/* ... Your accordion items ... */}
          {accordionItems.map((item, index) => (
            <div key={item.id}>
              <h2>
                <button
                  type="button"
                  className="flex items-center justify-between w-full py-5 font-medium text-gray-500 border-b border-gray-200  gap-3"
                  onClick={() => handleAccordion(item.id)}
                  aria-expanded={item.active}
                  aria-controls={item.id}>
                  <span className="text-center text-xl ">{item.title}</span>
                  <svg
                    className={`w-3 h-3 ${item.active ? "" : "rotate-180"}`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id={item.id}
                className={`${item.active ? "" : "hidden"}`}
                aria-labelledby={item.id}>
                <blockquote className="p-4 my-4 border-s-4 border-gray-300 bg-gray-50 ">
                  <p className="text-xl italic font-medium leading-relaxed text-gray-900 ">
                    {item.body}
                  </p>
                  <div className="flex flex-row gap-2">
                    <p className="text text-blue-950 font-bold ">
                      {item.verse}
                    </p>
                    <a
                      href={item.verseURL}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text text-blue-900 font-extrabold no-underline">
                      <ArrowTopRightCorner />
                    </a>
                  </div>
                </blockquote>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Questions;
