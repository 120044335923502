import React from "react";

const Card = (props) => {
  return (
    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow ">
      <img className="rounded-t-lg" src={props.image} alt="" />
      <div className="p-5">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
          {props.title}
        </h5>
        <div className="mb-3 font-normal text-gray-700 ">{props.body}</div>
      </div>
    </div>
  );
};

export default Card;
