import React from "react";
import Card from "../components/Card";
import blaivybesKlubas from "../assets/blaivybesKlubas.jpg";
import Youth from "../assets/youth.jpg";
import MoteruMalda from "../assets/mot_malda.jpg";
import BendraMalda from "../assets/bendraMalda.jpg";
import Lastele from "../assets/Lastele.jpg";
import SundayService from "../assets/sundayService.jpg";

const Tarnystes = () => {
  return (
    <div className="flex flex-col items-center mt-16 pt-8 ">
      <h1 className="text-4xl md:text-6xl pb-8 md:pb-16  font-semibold ">
        Prisijunk!
      </h1>

      <div className="grid md:grid-cols-2 xl:grid-cols-3 md:grid-flow-row px-4 gap-4 md:gap-8">
        <Card
          image={SundayService}
          title="Ateik sekmadienį"
          body="Tarnaudami vienas kitam Dievo duotomis dovanomis keičiamės į Kristaus panašumą.
        Sekmadieniais 11:00–14:00"
        />
        <Card
          image={BendraMalda}
          title="Melskis bendroje maldoje"
          body={
            <div>
              Meldžiamės, ieškom Jo valios, stovim už tuos kuriems sunku, nes
              daug gali karšta teisiojo malda.
              <br />
              Ketvirtadieniais 18:30–20:00
            </div>
          }
        />
        <Card
          image={Lastele}
          title="Biblijos studijos"
          body={
            <div>
              Kiekvieną mėnesį studijuojam naują temą. Užduodam klausimus.
              Diskutuojam. <br />
              Antradieniais 18:30–20:00 <br />
              Jonas +370 698 21303
            </div>
          }
        />
        <Card
          image={Youth}
          title="Prisijunk prie jaunimo"
          body={
            <div>
              Siekiame stiprinti tikėjimą Jėzumi ir krikščionišką pasaulėžiūrą.
              Todėl daug bendraujame, šlovinam Dievą, meldžiamės, planuojam
              renginius, keliaujam, nagrinėjam Bibliją, palaikom vienas kitą!
              <br />
              Rima +370 678 44935
            </div>
          }
        />
        <Card
          image={MoteruMalda}
          title="Dalinkis moterų maldoje"
          body={
            <div>
              Pastoviu bendravimu, malda ir palaikymu, padedame moterims
              išsilaisvinti nuo dvasinių žaizdų ir atsistoti ant kojų. <br />
              Natalja +370 618 32252
            </div>
          }
        />

        <Card
          image={blaivybesKlubas}
          title="Tapk laisvas blaivybės klube"
          body={
            <div>
              Konsultuojame ir padedame priklausomybę turintiems žmonėms
              integruotis atgal į visuomenę, suteikiame apgyvendinimą, padedame
              įsidarbinti.
              <br />
              Trečiadieniais 18:30 – 19:30
              <br />
              Svetlana – 8 655 99907
              <br />
              Andrius – 8 605 06446"
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Tarnystes;
