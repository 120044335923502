import "./App.css";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Route, Routes, Navigate } from "react-router-dom";
import Questions from "./pages/Questions";
import Aukoti from "./pages/Aukoti";
import Malda from "./pages/Malda";
import Tarnystes from "./pages/Tarnystes";
import Apie from "./pages/Apie";
import Tikejimas from "./pages/Tikejimas";
import Partneriai from "./pages/Partneriai";
function App() {
  return (
    <div className="flex flex-col  min-h-screen">
      <div className="grow  ">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/klausimai" element={<Questions />} />
          <Route path="/aukoti" element={<Aukoti />} />
          <Route path="/malda" element={<Malda />} />
          <Route path="/tarnystes" element={<Tarnystes />} />
          <Route path="/apie-mus" element={<Apie />} />
          <Route path="/tikejimas" element={<Tikejimas />} />
          <Route path="/partneriai" element={<Partneriai />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
