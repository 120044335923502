import React from "react";

const Aukoti = () => {
  return (
    <div className="flex flex-col items-center mt-16 py-8  ">
      <div className="text-5xl md:text-6xl pb-8 md:pb-16  font-semibold text-[#513b34] ">
        Aukoti
      </div>
      <div className="text-center text-2xl md:text-3xl pb-8 md:pb-16 font-bold px-4">
        Rekvizitai aukojant bankiniu pavedimu:
      </div>
      <div className="text-center text-xl">
        <span className=" font-bold">Pavadinimas:</span> EVANGELINIO TIKĖJIMO
        KRIKŠČIONIŲ BAŽNYČIA IŠGELBĖJIMO ŠVIESA
      </div>
      <div className="text-xl">
        <span className=" font-bold">Sąskaita:</span> LT 58 7300 0101 5820 8146
      </div>
      <div className="text-xl pb-16">
        Swedbank AB, <span className=" font-bold">Swift:</span> kodas: HABALT22
      </div>
      <div className="text-xl">
        <span className="font-bold">Pavedimo paskirtis: </span> „auka“
      </div>
      <div className="text-center px-4 text-xl pt-2">
        Jeigu norite pervesti <span className="font-bold">dešimtinę</span>,
        mokėjimo paskirtyje nurodykite „auka 10“.
      </div>
    </div>
  );
};

export default Aukoti;
