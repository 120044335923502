import React from "react";
import { Link } from "react-router-dom";

const Apie = () => {
  return (
    <div className="flex flex-col items-center mt-16 pt-8 ">
      <div className="text-center text-3xl md:text-5xl  pb-8 md:pb-16  font-semibold md:px-32 px-8 md:w-3/4 w-full">
        Kodėl bažnyčia vadinasi „Išgelbėjimo Šviesa“?
      </div>
      <div className="flex flex-col gap-8 px-4 md:px-16 md:w-3/4 w-full">
        <p>
          <span className="font-bold">Bažnyčia „Išgelbėjimo Šviesa“ </span>
          įkurta Klaipėdoje 1995 metais. Priklausome „Lietuvos Evangelinio
          Tikėjimo Krikščionių Sąjungai“ (
          <a
            className="underline font-bold"
            href="https://www.pentecost.lt/apie-mus/"
            target="_blank"
            rel="noopener noreferrer">
            LRETKS
          </a>
          , Sekmininkai), kurią sudaro 24 bendruomenės. 1918 metais įkurta
          pirmoji sekmininkų bažnyčia Lietuvoje. Sąjunga priklauso „Pasaulinei
          Dievo draugijos Asamblėjai“ ({" "}
          <a
            className="underline font-bold"
            href="https://worldagfellowship.org/"
            target="_blank"
            rel="noopener noreferrer">
            World Assemblies of God Fellowship
          </a>{" "}
          ).
        </p>
        <p>
          <span className="font-bold">Kodėl „Išgelbėjimo Šviesa“ </span>{" "}
          Biblijoje parašyta (Jono 8:12): „Jėzus vėl prabilo:{" "}
          <span className="font-bold">"Aš – pasaulio šviesa.</span> Kas seka
          manimi, nebevaikščios tamsybėse, bet turės{" "}
          <span className="font-bold">gyvenimo šviesą.“</span> Išgelbėjimą
          įmanoma gauti <span className="font-bold">tik tikėjimu</span> (ne
          darbais) į <span className="font-bold">Jėzų Kristų</span>, todėl ir
          <span className="font-bold"> skelbiame</span> šį išgelbėjimo kelią,
          šiam pasauliui <span className="font-bold">Kristų.</span>
        </p>
        <Link
          to="/tikejimas"
          className="flex flex-row items-center underline font-bold">
          Kuo mes tikime?{" "}
          <svg
            className="ml-2"
            xmlns="http://www.w3.org/2000/svg"
            id="Outline"
            viewBox="0 0 24 24"
            width="16"
            height="16">
            <path d="M23.12,9.91,19.25,6a1,1,0,0,0-1.42,0h0a1,1,0,0,0,0,1.41L21.39,11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H21.45l-3.62,3.61a1,1,0,0,0,0,1.42h0a1,1,0,0,0,1.42,0l3.87-3.88A3,3,0,0,0,23.12,9.91Z" />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default Apie;
