import React from "react";

const Malda = () => {
  return (
    <div className="flex flex-col items-center mt-16 pt-8 ">
      <div className="text-5xl md:text-7xl pb-8 md:pb-16  font-semibold text-[#513b34]">
        Malda
      </div>
      <div className="text-center text-2xl md:text-3xl pb-8  font-bold px-4">
        Jei reikalinga malda:
      </div>
      <div className="text-center text-xl pb-4">
        Paskambink pastoriui Dmitrij Romančik. Tel. nr: +370 682 30509
      </div>
      <div className="text-xl pb-4">Parašyk bažnyčios facook puslapyje:</div>
      <div className="text-xl">
        <a
          href="https://facebook.com/isgelbejimoSviesa"
          target="_blank"
          rel="noopener noreferrer"
          className="underline decoration-[#78350F] font-bold underline-offset-4">
          facebook.com/isgelbejimoSviesa
        </a>
      </div>
    </div>
  );
};

export default Malda;
